import {createBrowserRouter, RouteObject} from "react-router-dom"
import App from "./App"
import {UnexpectedErrorPage} from "./pages/errors/UnexpectedErrorPage"
import {sellingRoutes} from "./pages/selling/sellingRoutes"
import {remortgagingRoutes} from "./pages/remortgaging/remortgagingRoutes"

const routes: RouteObject[] = [
  {
    element: <App/>,
    children: [
      {
        errorElement: <UnexpectedErrorPage/>,
        children: [
          {
            lazy: () => import("./pages/selling/AppSelling"),
            children: sellingRoutes,
          },
          {
            lazy: () => import("./pages/remortgaging/AppRemortgaging"),
            children: remortgagingRoutes,
          },
          {
            path: "/login",
            lazy: () => import("./pages/auth/LoginPage"),
          },
          {
            path: "/login/auth",
            lazy: () => import("./pages/auth/AuthPage"),
          },
          {
            path: "/unauthorised",
            lazy: () => import("./pages/errors/UnauthorisedPage"),
          },
          {
            path: "*",
            lazy: () => import("./pages/errors/NotFoundPage"),
          },
        ],
      },
    ],
  },
]

export const router = createBrowserRouter(routes)
