import React from 'react'
import {Outlet} from 'react-router-dom'
import {Provider} from 'react-redux'
import {store} from './redux/store'
import {ThemeProvider} from "@mui/material"
import {rootTheme} from "./theme"
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
import {ReactQueryDevtools} from "@tanstack/react-query-devtools"
import {AuthProvider} from "./hooks/UseAuth"
import {Header} from "./components/header/Header"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={rootTheme}>
          <AuthProvider>
            <>
              <Header/>
              <Outlet/>
            </>
          </AuthProvider>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false}/>
      </QueryClientProvider>
    </Provider>
  )
}

export default App
