import React, {createContext, ReactElement, useCallback, useContext,} from "react"
import * as authApi from "../api/AuthApi"
import {isLoggedIn} from "../api/AuthApi"
import {getCookie} from "../CookieUtils"
import {isNotBlank} from "../components/textUtils"

interface Auth {
  readonly isSignedIn: () => Promise<boolean>
  readonly signOut: () => void
  readonly hasUsername: () => boolean
  readonly getUsername: () => string
}

interface ProvideAuthProps {
  readonly children: ReactElement
}

const AuthContext = createContext<Auth>({
  isSignedIn: () => Promise.resolve(false),
  signOut: () => {
  },
  hasUsername: () => false,
  getUsername: () => "",
})

export function AuthProvider({children}: ProvideAuthProps): ReactElement {

  const signOut = useCallback((): void => {
    void authApi.logout()
  }, [])

  const isSignedIn = useCallback(async (): Promise<boolean> => {
    const username = getCookie("username")
    if (username) {
      try {
        const response = await isLoggedIn()
        return response.isLoggedIn
      } catch (error) {
        console.error("Error checking if user is signed in", error)
        return false
      }
    }
    return false
  }, [])

  const auth: Auth = {
    isSignedIn,
    signOut,
    hasUsername: () => isNotBlank(getCookie("username")),
    getUsername: () => getCookie("username"),
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export function useAuth(): Auth {
  return useContext(AuthContext)
}
