import {ReactElement} from "react"
import {Page} from "../Page"
import {UnexpectedErrorMessage} from "./UnexpectedErrorMessage"

export function UnexpectedErrorPage(): ReactElement {
  return (
    <Page title={"Error"}>
      <UnexpectedErrorMessage/>
    </Page>
  )
}
