import {createTheme, Theme} from "@mui/material"

// Colours generated using https://m2.material.io/inline-tools/color/

/** 600 is the main shade */
export const conveydGreen = {
  50: "#dcf2fb",
  100: "#bddbe2",
  200: "#9dc1cb",
  300: "#7ba6b3",
  400: "#6192a0",
  500: "#467f8e",
  600: "#3b707d",
  700: "#2d5c68",
  800: "#204853",
  900: "#0f333c",
}

/** 300 is the main shade */
export const conveydOrange = {
  50: "#fff3e2",
  100: "#ffe1b7",
  200: "#ffcd8a",
  300: "#ffb85d",
  400: "#ffa93f",
  500: "#ff9b2b",
  600: "#fa8f29",
  700: "#f48025",
  800: "#ed7122",
  900: "#e3581e",
}

/** 900 is the main shade */
export const conveydBlack = {
  50: "#f2f7f3",
  100: "#e7ece8",
  200: "#d9deda",
  300: "#c5cac5",
  400: "#9fa4a0",
  500: "#7d827e",
  600: "#575c58",
  700: "#454946",
  800: "#282c28",
  900: "#030a04",
}

/** 500 is the main shade */
export const conveydRed = {
  50: "#fce9e6",
  100: "#ffcbb9",
  200: "#ffaa8d",
  300: "#ff895e",
  400: "#ff6e3a",
  500: "#ff5412",
  600: "#f44f0d",
  700: "#e64807",
  800: "#d84102",
  900: "#c03300",
}

let root: Theme = createTheme({
  palette: {
    primary: {
      main: conveydBlack[900],
    },
    secondary: {
      main: conveydOrange[300],
      light: conveydOrange[100],
      dark: conveydOrange[800],
    },
  },
  // https://www.modularscale.com/?1&rem&1.25
  typography: {
    h1: {
      fontSize: "1.953rem",
    },
    h2: {
      fontSize: "1.563rem",
    },
    h3: {
      fontSize: "1.25rem",
    },
    h4: {
      fontSize: "1rem",
    },
    h5: {
      fontSize: "1rem",
    },
    h6: {
      fontSize: "1rem",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "1rem",
    },
    button: {
      fontSize: "1rem",
      lineHeight: "1",
    },
    fontFamily: ["DMSans", "sans-serif"].join(","),
  },
})

root = createTheme(root, {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
                @font-face {
                  font-family: 'DMSans';
                }`,
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: root.palette.primary.main,
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: "lg",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: root.palette.secondary.main,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
})

export const rootTheme: Theme = root
