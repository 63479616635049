import {ReactElement} from "react"
import {Alert, AlertTitle, Typography} from "@mui/material"

export function UnexpectedErrorMessage(): ReactElement {

  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      <Typography>
        An unexpected error happened, refresh the page to try again and contact support if the problem persists.
      </Typography>
    </Alert>
  )
}
