import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface FormState {
}

const initialState: FormState = {
}

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setThing: (state, action: PayloadAction<{}>) => {
      state = action.payload
    },
  },
})

export const {setThing} = formSlice.actions
export default formSlice.reducer
