export function sentenceCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function isBlank(
  val: string | undefined | null,
): val is undefined | null | "" {
  return val === undefined || val === null || val.trim() === ""
}

export function isNotBlank(val: string | undefined | null): val is string {
  return !isBlank(val)
}

export function formatCurrency(val: string, hasCurrencySign?: boolean): string {
  let cleanedString = val.trim().replace(/[£,]/g, "")
  const numberValue = Number(cleanedString)
  return `${hasCurrencySign ? "£" : ""}${numberValue.toLocaleString()}`
}
