import { forwardRef, ReactElement } from "react"
import { Link as RouterLink } from "react-router-dom"
import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material"

export const InternalLink = forwardRef(
  (props: MuiLinkProps<typeof RouterLink>, ref): ReactElement => {
    return <MuiLink component={RouterLink} ref={ref} {...props} />
  },
)

export interface ExternalLinkProps extends MuiLinkProps {
  readonly openInNewTab?: boolean
}

export const ExternalLink = forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  ({ openInNewTab = true, ...props }: ExternalLinkProps, ref): ReactElement => {
    return (
      <MuiLink
        {...props}
        ref={ref}
        target={openInNewTab ? "_blank" : undefined}
        rel={openInNewTab ? "noopener noreferrer" : undefined}
      />
    )
  },
)
