import { ReactElement } from "react"
import { Page } from "./Page"
import { Navigate, useLocation } from "react-router-dom"

export function RedirectPage({ to }: { to: string }): ReactElement {
  const { pathname } = useLocation()
  return (
    <Page title={`Redirecting ${pathname} to ${to}`} hideTitle>
      <Navigate to={to} />
    </Page>
  )
}
