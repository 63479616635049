import {RouteObject} from "react-router-dom"
import {RedirectPage} from "../RedirectPage"

export const remortgagingRoutes: RouteObject[] = [
  {
    index: true,
    element: <RedirectPage to={"remortgaging/dashboard"}/>
  },
  {
    path: "remortgaging",
    children: [
      {
        path: "dashboard",
        lazy: () => import("../remortgaging/DashboardLayout"),
        children: [
          {
            index: true,
            lazy: () => import("./CasesPage"),
          },
          {
            path: ":caseId",
            lazy: () => import("../remortgaging/CustomerPage"),
            children: [
              {
                path: "current-mortgage-details",
                lazy: () => import("./summarySections/MortgageReviewDetailsDrawer"),
              },
              {
                path: "new-mortgage-details",
                lazy: () => import("./summarySections/MortgageReviewDetailsDrawer"),
              },
              {
                path: "property-search-details",
                lazy: () => import("./summarySections/MortgageReviewDetailsDrawer"),
              },
            ]
          },
          {
            path: ":customerId/cot",
            lazy: () => import("../remortgaging/CertificateOfTitlePage"),
          }
        ],
      },
    ],
  },
]
