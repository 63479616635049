import * as axiosWrapper from "./AxiosWrapper"
import {throwApiError} from "./AxiosWrapper"

export interface IsLoggedInResponse {
  readonly isLoggedIn: boolean
}

export interface SignupDetails {
  readonly firstName: string
  readonly surname: string
  readonly email: string
}

export const login = async (
  username: string,
  signal?: AbortSignal,
): Promise<void> => {
  return axiosWrapper
    .post<void>(`/auth`, {username: username}, {signal})
    .catch(throwApiError)
}

export const logout = async (
  signal?: AbortSignal,
): Promise<void> => {
  return axiosWrapper
    .post<void>(`/auth/logout`, {}, {signal})
    .catch(throwApiError)
}

export const isLoggedIn = async (
  signal?: AbortSignal,
): Promise<IsLoggedInResponse> => {
  return axiosWrapper
    .get<IsLoggedInResponse>(`/auth/verify`, {signal})
    .catch(throwApiError)
}

export const authenticate = async (
  token: string,
  signal?: AbortSignal,
): Promise<void> => {
  return axiosWrapper
    .get<void>(`/auth/${token}`, {signal})
    .catch(throwApiError)
}
