import {RouteObject} from "react-router-dom"

export const sellingRoutes: RouteObject[] = [
  {
    path: "selling",
    children: [
      {
        path: "dashboard",
        lazy: () => import("../selling/DashboardLayout"),
        children: [
          {
            index: true,
            lazy: () => import("./SellingHomePage"),
          },
          {
            path: "verification",
            lazy: () => import("../selling/VerificationPage"),
          },
        ],
      },
    ],
  },
]
