import {Box} from "@mui/material"
import {ReactElement} from "react"
import LogoSmall from "./logo-small-769x769.png"
import LogoFull from "./logo-1024x265.png"
import {ExternalLink} from "../links/Links"

interface LogoProps {
  readonly to: string
  readonly variant?: "full" | "circle"
}

export function Logo({to, variant = "full"}: LogoProps): ReactElement {
  return (
    <ExternalLink href={to}>
      <Box
        component={"img"}
        src={variant === "full" ? LogoFull : LogoSmall}
        alt={"Home"}
        sx={{maxHeight: "35px"}}
      />
    </ExternalLink>
  )
}
