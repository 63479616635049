import {AppBar, AppBarProps, Box, Container, Toolbar} from "@mui/material"
import {ReactElement} from "react"
import {Breakpoint} from "@mui/system/createTheme/createBreakpoints"
import {Logo} from "./Logo"
import {InternalLink} from "../links/Links"
import {conveydBlack} from "../../theme"

interface HeaderProps extends AppBarProps {
  readonly maxWidth?: Breakpoint | false
}

export function Header({maxWidth, ...props}: HeaderProps): ReactElement {
  return (
    <AppBar {...props} elevation={0} position={"sticky"} color={"inherit"}>
      <Container maxWidth={maxWidth || false} sx={(theme) => ({borderBottom: `1px solid ${theme.palette.grey[300]}`})}>
        <Toolbar disableGutters>
          <Box
            sx={{
              display: "flex",
              justifyContent: {xs: "center", sm: "flex-start"},
              width: "100%",
            }}
          >
            <Logo to={"https://www.conveyd.co.uk"}/>
          </Box>
          <Box>
            <InternalLink to={"/login?doLogout=true"}
                          sx={{
                            fontWeight: 400,
                            textDecoration: "none",
                            ":hover": {
                              fontWeight: 700,
                              backgroundColor: conveydBlack[100]
                            },
                            padding: "0.5rem",
                            border: `1px solid ${conveydBlack[900]}`,
                            borderRadius: "0.5rem"
                          }}>
              Logout
            </InternalLink>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
